import React, { useEffect } from 'react';
import config from './../config';
import 'url-search-params-polyfill';
import { getWebpageTitle } from '@/utils/getWebpageTitle';
import { getMetaDescription } from '@/utils/getMetaDescription';
import { checkForWrongDomain } from '@/utils/checkForWrongDomain';
import Layout from '@/components/Layout';
import DomainExpired from '../components/DomainExpired';
import Home from '../containers/Home';
import { getSSRStoreData } from '../utils/ssrPageData';
import { getRequiredCtxProps } from '../utils/getRequiredCtxProps';
import { useSetSSRStore, useSSRSelector } from '../redux';
import checkIfThemePreview from '@/utils/checkIfThemePreview';
import { IS_SERVER } from '../utils/checkRenderEnv';

const Redirection = (props) => {
  useSetSSRStore(props.ssrStore);
  const storeInfo = useSSRSelector((state) => state.storeReducer.store);
  const allProps = {
    ...props,
    storeInfo,
  };

  const isPreview = checkIfThemePreview();

  useEffect(() => {
    const hostname = window.location.hostname || '';
    let storename = hostname.split('.')[0];

    const urlParams = new URLSearchParams(window.location.search);
    const utmSource = urlParams.get('utm_source');

    if (!IS_SERVER && localStorage?.getItem('utm_source') !== utmSource) {
      localStorage?.setItem('utm_source', utmSource);
    }

    if (storename.startsWith('d-temporary-')) {
      window.location = `${config.APP_BASE_URL}/${storename.split('-')[1]}-${
        storename.split('-')[2]
      }/${utmSource ? `?utm_source=${utmSource}` : ''}`;
    } else if (storename.startsWith('d-')) {
      /** Uncomment for Development */
      // window.location = `${config.redirect_base_url}:3006/${storename.split('-')[1]}`;
      /** uncomment for stage and prod */
      window.location = `${config.APP_BASE_URL}/${storename.split('-')[1]}/${
        utmSource ? `?utm_source=${utmSource}` : ''
      }`;
    } else if (storename.startsWith('showroom')) {
      storename = window.location.pathname.split('/')[1];

      /** Uncomment for Development */
      // window.location = `${config.redirect_base_url}:3006/${storename}`;
      /** uncomment for stage and prod */
      window.location = `${config.APP_BASE_URL}/${storename}/${
        utmSource ? `?utm_source=${utmSource}` : ''
      }`;
    }
  }, []);

  return (
    <Layout
      store={storeInfo}
      title={storeInfo && getWebpageTitle(storeInfo, allProps.seo)}
      Icon={storeInfo?.store_info?.logo_image}
      metaDescription={getMetaDescription(storeInfo, allProps.seo)}
      keywords={allProps.seo ? allProps.seo.keywords.toString() : ''}
      favIcon={storeInfo?.favicon}
      OGImage={storeInfo?.store_info?.logo_image}
      isCustomDomain={allProps.isCustomDomain}
      {...allProps}
    >
      {/* removeing this to test custom domain. Do add this later if required. */}

      {/* <div className="redirection-container">
        <span className="redirection-success">Loading...</span>
      </div> */}
      {!isPreview && storeInfo?.domain_info?.status === 2 ? (
        <DomainExpired storeInfo={storeInfo} />
      ) : (
        <Home {...allProps} />
      )}
    </Layout>
  );
};

/**
 * @param {import('next').NextPageContext} ctx
 * @returns {any}
 */
export async function getServerSideProps(ctx) {
  if (checkForWrongDomain(ctx)) return {};

  let propData = {
    ssrStore: {},
    catalogCategories: [],
    catalogTags: [],
    collections: [],
  };

  const ssrPropData = await getSSRStoreData(ctx, {
    additionalDataToFetch: [
      'theme',
      'collections',
      'categories',
      'themeHtml',
      'seo',
      'coupons',
      'additionalPagesList',
    ],
  });

  propData = ssrPropData;

  const extraProps = getRequiredCtxProps(ctx);

  return { props: { ...propData, ...extraProps } };
}

export default Redirection;
